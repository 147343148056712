<template>
  <div class="page page-settings-all scroll primary">

    <PageHeader
      :dark="true"
      :icon="$t('SETTINGS.ICON')"
      :title="$t('SETTINGS.TITLES')"
    />

    <div class="ml-10 mr-10">
      <v-alert type="info" clearable dark>
        <p class="ma-0">
        <small>Vous êtes connecté en tant que <strong>{{userRole}}</strong> dans le bureau <strong>{{userOffice}}</strong> de l'entreprise <strong>{{userCompany}}</strong>.</small></p></v-alert>
    </div>

    <v-row class="ma-10 mt-0">

      <v-col cols="10" md="6" lg="5" xl="4">
        <v-list class="pa-0 transparent" dark>

          <!-- ALL -->
          <v-list-item to="/settings/account">
            <v-list-item-icon><v-icon>{{$t('ACCOUNT.ICON')}}</v-icon></v-list-item-icon>
            <v-list-item-title>{{$t('ACCOUNT.TITLE')}}</v-list-item-title>
          </v-list-item>


          <!-- MANAGER -->
          <template v-if="userRole === 'manager'">
            <v-subheader>MANAGER</v-subheader>
            <v-list-item to="/settings/manager/users">
              <v-list-item-icon><v-icon>{{$t('USER.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('USER.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/manager/office">
              <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('OFFICE.TITLE')}}</v-list-item-title>
                <v-list-item-subtitle>{{userOffice}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/manager/offices">
              <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('OFFICE.TITLES')}}</v-list-item-title>
                <v-list-item-subtitle>{{userOffice}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/manager/url_tag_manager">
              <v-list-item-icon><v-icon>{{$t('PUBLICATION_URL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('PUBLICATION_URL.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/manager/application_email_manager">
              <v-list-item-icon><v-icon>{{$t('APPLICATION_EMAIL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('APPLICATION_EMAIL.TITLES')}}</v-list-item-title>
            </v-list-item>
            <permission>
              <v-list-item to="/settings/admin/offices">
                <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t('OFFICE.TITLE')}}</v-list-item-title>
                  <v-list-item-subtitle>{{userOffice}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </permission>
          </template>


          <!-- ADMIN -->
          <template v-if="userRole === 'admin'">
            <v-subheader>ADMIN</v-subheader>
            <v-list-item to="/settings/admin/users">
              <v-list-item-icon><v-icon>{{$t('USER.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('USER.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/admin/offices">
              <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('OFFICE.TITLE')}}</v-list-item-title>
                <v-list-item-subtitle>{{userOffice}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/admin/company">
              <v-list-item-icon><v-icon>{{$t('COMPANY.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('COMPANY.TITLE')}}</v-list-item-title>
                <v-list-item-subtitle>{{userCompany}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/admin/products">
              <v-list-item-icon><v-icon>{{$t('PRODUCT.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('PRODUCT.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/admin/url_tag_manager">
              <v-list-item-icon><v-icon>{{$t('PUBLICATION_URL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('PUBLICATION_URL.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/admin/application_email_manager">
              <v-list-item-icon><v-icon>{{$t('APPLICATION_EMAIL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('APPLICATION_EMAIL.TITLES')}}</v-list-item-title>
            </v-list-item>
          </template>


          <!-- XADMIN -->
          <template v-if="userRole === 'xadmin'">
            <v-subheader>XADMIN</v-subheader>
            <v-list-item to="/settings/xadmin/users">
              <v-list-item-icon><v-icon>{{$t('USER.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('USER.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/office">
              <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('OFFICE.TITLE')}}</v-list-item-title>
                <v-list-item-subtitle>{{userOffice}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/xadmin/company">
              <v-list-item-icon><v-icon>{{$t('COMPANY.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('COMPANY.TITLE')}}</v-list-item-title>
                <v-list-item-subtitle>{{userCompany}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings/xadmin/url_tag_manager">
              <v-list-item-icon><v-icon>{{$t('PUBLICATION_URL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('PUBLICATION_URL.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/application_email_manager">
              <v-list-item-icon><v-icon>{{$t('APPLICATION_EMAIL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('APPLICATION_EMAIL.TITLES')}}</v-list-item-title>
            </v-list-item>
          </template>

        </v-list>
      </v-col>

      <v-col cols="10" md="4" lg="3" xl="2">
        <v-list class="pa-0 transparent" dark>
          <template v-if="userRole === 'xadmin'">
            <v-subheader>XADMIN MANAGEMENT SETTINGS</v-subheader>
            <v-list-item to="/settings/xadmin/users">
              <v-list-item-icon><v-icon>{{$t('USER.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('USER.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/offices">
              <v-list-item-icon><v-icon>{{$t('OFFICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('OFFICE.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/companies">
              <v-list-item-icon><v-icon>{{$t('COMPANY.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('COMPANY.TITLES')}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-col>


      <v-col cols="10" md="4" lg="3" xl="2">
        <v-list class="pa-0 transparent" dark>
          <template v-if="userRole === 'xadmin'">
            <v-subheader>XADMIN GLOBAL SETTINGS</v-subheader>
            <v-list-item to="/settings/xadmin/channels">
              <v-list-item-icon><v-icon>{{$t('CHANNEL.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('CHANNEL.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/products">
              <v-list-item-icon><v-icon>{{$t('PRODUCT.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('PRODUCT.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/roles">
              <v-list-item-icon><v-icon>{{$t('ROLE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('ROLE.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/countries">
              <v-list-item-icon><v-icon>{{$t('COUNTRY.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('COUNTRY.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/currencies">
              <v-list-item-icon><v-icon>{{$t('CURRENCY.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('CURRENCY.TITLES')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings/xadmin/services">
              <v-list-item-icon><v-icon>{{$t('SERVICE.ICON')}}</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('SERVICE.TITLES')}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-col>

    </v-row>


  </div>
</template>

<style>
.page-settings-all::after {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  background-image: url("/img/bg/earth-connected.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
  filter: grayscale(100%) blur(1px);
  opacity: 0.15;
}
.page-settings-all > * {
  z-index: 1
}
</style>

<script>
import { mapState } from 'vuex'

import PageHeader from '@/components/PageHeader'
import Permission from '../../../components/Permission.vue'
// import PageMain from '@/components/PageMain'

export default {
  name: 'PageSettingsAll',
  components: {
    PageHeader,
    Permission,
    // PageMain
  },
  
  computed: {
    ...mapState('user', {
      userRole: state => state.user.role.title,
      userOffice: state => state.user.office.title,
      userCompany: state => state.user.company.title,
    }),
  },
}
</script>
